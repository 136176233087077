<template>
<section class="aishop_m">
   <img class="banner" :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   <ul>
      <p class="big_title">{{ $t("PC.Product.AISHOP.3anq1t") }}</p>
      <li>
         <img src="../../../assets/zh/images-mobile//suanfas_img1@2x.png" alt="" />
         <div class="content_box cb1">
            <p class="title">{{ $t("PC.Product.AISHOP.hz1oeu") }}</p>
            <p class="text">
               {{ $t("PC.Product.AISHOP.mrvi56") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../../assets/zh/images-mobile//suanfas_img2@2x.png" alt="" />
         <div class="content_box cb2">
            <p class="title">{{ $t("PC.Product.AISHOP.czua2c") }}</p>
            <p class="text">
               {{ $t("PC.Product.AISHOP.avfxgp") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../../assets/zh/images-mobile//suanfas_img3@2x.png" alt="" />
         <div class="content_box cb3">
            <p class="title">{{ $t("PC.Product.AISHOP.yu3lnn") }}</p>
            <p class="text">
               {{ $t("PC.Product.AISHOP.b9fkny") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../../assets/zh/images-mobile//suanfas_img4@2x.png" alt="" />
         <div class="content_box cb3">
            <p class="title">{{ $t("PC.Product.AISHOP.p8wgaj") }}</p>
            <p class="text">
               {{ $t("PC.Product.AISHOP.cbex8y") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../../assets/zh/images-mobile//suanfas_img5@2x.png" alt="" />
         <div class="content_box cb3">
            <p class="title">{{ $t("PC.Product.AISHOP.nmthch") }}</p>
            <p class="text">
               {{ $t("PC.Product.AISHOP.gvcc8r") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../../assets/zh/images-mobile//suanfas_img6@2x.png" alt="" />
         <div class="content_box cb3">
            <p class="title">{{ $t("PC.Product.AISHOP.p6zqs6") }}</p>
            <p class="text">{{ $t("PC.Product.AISHOP.q77kdm") }}</p>
         </div>
      </li>
   </ul>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         banner: require('../../../assets/zh/images-mobile//suanfas_banner@2x.png'),
         banner_en: require('../../../assets/en/images-mobile//suanfas_banner@2x.png'),
      }
   }
};
</script>

<style lang="scss" scoped>
.aishop_m {
   margin-top: 48px;
   word-break: break-word !important;

   img.banner {
      width: 100%;
      height: 150px;
   }

   ul {
      box-sizing: border-box;
      padding: 20px;

      &>p.big_title {
         color: #333;
         text-align: center;
         padding-bottom: 8px;
         font-size: 16px;
         line-height: 22px;
      }

      li {
         width: 335px;
         overflow: hidden;
         margin-bottom: 16px;

         img {
            width: 335px;
            height: 133px;
         }

         .content_box {
            padding: 12px 16px 18px;

            background-color: #f5f6f9;

            p.title {
               color: #333;
               font-size: 14px;
               line-height: 20px;
               padding-bottom: 8px;
               text-align: center;
               font-weight: 600;
            }

            .text {
               color: #666;
               font-size: 12px;
               line-height: 18px;
               width: 100%;
               font-size: 12px;
               line-height: 21px;
            }

            .more {
               display: inline-block;
               margin-top: 12px;
               width: 72px;
               height: 25px;
               border: 1px solid #666666;
               font-size: 10px;
               font-family:   PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               color: #666666;
               line-height: 24px;
               text-align: center;
            }
         }
      }
   }
}
</style>
